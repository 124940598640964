import { mapHelper } from "@/utils/utils";

// 所属类别
const subHandle = [
  {
    text: "项目应用",
    value: 0,
  },
  {
    text: "电商应用",
    value: 1,
  },
];

const { map: subHandleMap, setOps: setSubHandleOps } =
  mapHelper.setMap(subHandle);
// 所属类别
const topicType = [
  {
    text: "意见",
    value: 47,
  },
  {
    text: "推荐",
    value: 48,
  },
  {
    text: "分享",
    value: 49,
  },
  {
    text: "好人好事",
    value: 50,
  },
];

const { map: topicTypeMap, setOps: topicTypeOps } = mapHelper.setMap(topicType);
export { subHandleMap, setSubHandleOps, topicTypeMap };
